import React from "react"
import SEO from "./../seo"
import Footer from "./../Footer"
import styles from "./../../scss/pages/_labanderaentusmanos.module.scss"

import logo from "./../../images/Esports/logo-bud.svg"
import { Container } from "react-bootstrap"

export default function Success() {
  return (
    <div styles={{ overflow: "hidden" }}>
      <SEO title="La bandera en tus manos" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <div className={styles.container}>
              <div>
                <img src={logo} className={styles.logo} />
              </div>
              <div className={styles.wrapText}>
                <div className={styles.titleContainer}>
                  <h1 className={styles.title}>
                    ¡Ya estás participando por la bandera!{" "}
                  </h1>
                </div>
                <h1 className={styles.infoTextOne}>
                  El 4/11 vamos a estar anunciando al ganador/a <br /> a través de las
                  redes de Bud @budweiser_ar.
                </h1>
              </div>
              <div>
                <h1 className={styles.infoTextTwo}>
                  Seguinos en IG y entérate <br />  si vas a vivir el Mundial <br /> con
                  la bandera firmada por el capitán.
                </h1>
                <a
                  className={styles.btnContainer}
                  target="_blank"
                  href="https://www.instagram.com/budweiser_ar/"
                >
                  <div className={styles.followBtn}>Seguirnos</div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
