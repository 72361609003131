import React from "react"
import styles from "../../scss/components/Labanderaentusmanos/_CustomCheckBox.module.scss"

export default function CustomCheckBox({
  isFirstQ,
  selectedInput,
  setSelectedInput,
  selectedInput2,
  setSelectedInput2,
  handleChange,
  handleChange2,
 
}) {
  const RadioInput = ({
    name,
    name2,
    label,
    value,
    isChecked,
    handleChange,
    handleChange2,
  }) => {
    const handleRadioChange = e => {
      const { id } = e.currentTarget
      handleChange(id)
    }
    const handleRadioChange2 = e => {
      const { id } = e.currentTarget
      handleChange2(id)
    }

    return (
      <div>
        <div style={{ display: "flex", height: "100%" }}>
          <input
            type="radio"
            className={styles.customRadio}
            name={name}
            name2={name2}
            id={value}
            checked={isChecked}
            onChange={isFirstQ ? handleRadioChange : handleRadioChange2}
          />
          <label htmlFor={value}>
            <h3 className={styles.labelText}>{label}</h3>
          </label>
        </div>
      </div>
    )
  }

  const RadioGropupInput = () => {
    return (
      <div>
        {isFirstQ ? (
          <>
            <RadioInput
              name="option"
              value="Cocinando/ comiendo solo o acompañado"
              label="Cocinando/ comiendo solo o acompañado"
              isChecked={
                selectedInput === "Cocinando/ comiendo solo o acompañado"
              }
              handleChange={handleChange}
          
            />
            <RadioInput
              name="option"
              value="Cuando me quiero relajar, escuchando música o usando RRSS"
              label="Cuando me quiero relajar, escuchando música o usando RRSS"
              isChecked={
                selectedInput ===
                "Cuando me quiero relajar, escuchando música o usando RRSS"
              }
              handleChange={handleChange}
       
            />
            <RadioInput
              name="option"
              value="Con amigos en un bar o en una casa"
              label="Con amigos en un bar o en una casa"
              isChecked={selectedInput === "Con amigos en un bar o en una casa"}
              handleChange={handleChange}
          
            />
            <RadioInput
              name="option"
              value="Antes o durante una fiesta o evento"
              label="Antes o durante una fiesta o evento"
              isChecked={
                selectedInput === "Antes o durante una fiesta o evento"
              }
              handleChange={handleChange}
          
            />
            <RadioInput
              name="option"
              value="Trasladándome a algún lugar o mientras estoy en mi casa"
              label="Trasladándome a algún lugar o mientras estoy en mi casa"
              isChecked={
                selectedInput ===
                "Trasladándome a algún lugar o mientras estoy en mi casa"
              }
              handleChange={handleChange}
          
            />
            <RadioInput
              name="option"
              value="No tomo cerveza"
              label="No tomo cerveza"
              isChecked={selectedInput === "No tomo cerveza"}
              handleChange={handleChange}
      
            />
          </>
        ) : (
          <>
            <RadioInput
              name2="option"
              value="secondq-si"
              label="Sí"
              isChecked={selectedInput2 === "secondq-si"}
              handleChange2={handleChange2}
          
            />
            <RadioInput
              name2="option"
              value="secondq-no"
              label="No"
              isChecked={selectedInput2 === "secondq-no"}
              handleChange2={handleChange2}
             
            />
          </>
        )}
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {isFirstQ
          ? "¿Cuándo tomas cerveza usualmente?"
          : "¿Pagarías entrada por ir a un Fan Fest a ver un partido de Argentina durante el mundial con djs, cerveza Budweiser, comida y sorteos de regalos?"}
      </h1>
      <RadioGropupInput
        selectedInput={selectedInput}
        setSelectedInput={setSelectedInput}
        handleChange={handleChange}
        selectedInput2={selectedInput2}
        setSelectedInput2={setSelectedInput2}
        handleChange2={handleChange2}
       
      />
    </div>
  )
}
